import { render, staticRenderFns } from "./TheStakedLayout.vue?vue&type=template&id=4d8c5b2e&scoped=true"
import script from "./TheStakedLayout.vue?vue&type=script&lang=js"
export * from "./TheStakedLayout.vue?vue&type=script&lang=js"
import style0 from "./TheStakedLayout.vue?vue&type=style&index=0&id=4d8c5b2e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d8c5b2e",
  null
  
)

export default component.exports